import React      from "react";

import Layout   from '../components/layout';
import Headline from '../components/atoms/headline/headline';
import Stage    from '../components/molecules/stage/stage';

export default ({ data }) => (
  <Layout siteTitle="ChancenCheckin">
    <Stage fullHeight={true} fullWidth={false} alignVert="middle">
      <Headline>404 - Seite nicht gefunden</Headline>
    </Stage>
  </Layout>
)
